import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import CssBaseline from '@mui/material/CssBaseline';
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAXprUioFVWzBmohk6ZRURGYvYn3yfWXaE",
  authDomain: "finnbanks-mono.firebaseapp.com",
  projectId: "finnbanks-mono",
  storageBucket: "finnbanks-mono.appspot.com",
  messagingSenderId: "469342925698",
  appId: "1:469342925698:web:717fc3f36ace2262f4159b",
  measurementId: "G-G68L2L630F"
};

// Initialize Firebase
initializeApp(firebaseConfig);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <CssBaseline enableColorScheme/>
    <App />
  </React.StrictMode>
);
