import { Box, Button, Card, Grid, Typography, useTheme } from "@mui/material"
import BaseMediaButton from "../components/Homepage/Links/BaseMediaButton"
import { Checkroom, Email, GitHub, LinkedIn } from "@mui/icons-material"
import Headshot from "../components/Homepage/Headshot"
import { useState } from "react"

interface HomepageViewProps {

}

const HomepageView = (props: HomepageViewProps) => {
    const theme = useTheme()
    const [showAboutMe, setShowAboutMe] = useState<boolean>(false);

    return (
        <Box sx={{ display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', backgroundColor: theme.palette.background.default }}>
            <Grid container spacing={1} sx={{ flexDirection: 'row', display: 'flex', alignItems: 'end', justifyContent: 'center'}}>
                <Grid item xs={10} md={4} lg={3}>
                <Headshot/>
                </Grid>
                <Grid item xs={10} md={4} lg={3}>
                <Box sx={{ flexDirection: 'column', display: 'flex', alignItems: 'start', ml: 1, width: '100%'}}>
                    <Typography variant="h2" color={theme.palette.text.primary}>Finn Banks</Typography>
                    <Box sx={{ flexDirection: 'row', display: 'flex', alignItems: 'start', transition: 'margin-bottom ease 1s'}}>
                        <BaseMediaButton icon={<Checkroom/>} internalUrl="dressr" color="warning" />
                    </Box>
                    <Box sx={{ flexDirection: 'row', display: 'flex', alignItems: 'start', transition: 'margin-bottom ease 1s'}}>
                        <Button variant="outlined" color={showAboutMe ? "warning" : "primary"} sx={{ mr: 1}} onClick={() => setShowAboutMe(!showAboutMe)}>About Me</Button>
                        <BaseMediaButton icon={<GitHub />} externalUrl="https://github.com/banksfinn" color="info" />
                        <BaseMediaButton icon={<LinkedIn />} externalUrl="https://www.linkedin.com/in/finn-banks" color="info" />
                        <BaseMediaButton icon={<Email />} externalUrl="mailto:banks.finn@gmail.com" color="info" />
                    </Box>
                    <Box sx={{ maxHeight: showAboutMe ? "500px" : "0px", transition: 'max-height ease 1s', mt: 1}}>
                        <Card sx={{ p: 1, opacity: showAboutMe ? '1' : '0', transition: 'opacity ease 1s 0.2s' }}>
                        <Typography sx={{ width: "100%", opacity: showAboutMe ? '1' : '0', transition: 'opacity ease 1s 0.2s'}} variant="body1" color={theme.palette.text.secondary}>
                            I'm a full stack engineer living in San Francisco. <br/>
                            I use this website primarily as a landing point and organizational method for my various hobbies.
                        </Typography>
                        </Card>
                    </Box>
                </Box>
                </Grid>
            </Grid>
        </Box>
    )
}
export default HomepageView