import { Box } from "@mui/material"
import headshot from "../../resources/FinnHeadshotCentered.jpeg"
import './Headshot.css';

const Headshot = () => {
    return (
        <Box sx={{ display: 'flex', p: 1, backgroundColor: "#212121"}}>
            <img src={headshot} alt="It's a headshot" className="headshot-image" />;
        </Box>
    )
}

export default Headshot