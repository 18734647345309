import { Route, Routes } from 'react-router-dom'
import HomepageView from '../views/HomepageView'
import DressrView from '../views/DressrView'

const Router = () => {
    return (
        <Routes>
            <Route index element={<HomepageView/>}/>
            <Route path="dressr" element={<DressrView />}/>
        </Routes>
    )
}
export default Router