import { ThemeProvider } from '@mui/material';
import Router from './router/router';
import { BrowserRouter } from 'react-router-dom';
import generateTheme from './theme/theme';
import "./App.css"

function App() {
  const theme = generateTheme({
    mode: "dark"
  })
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Router/>
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
}

export default App;
