import { IconButton } from "@mui/material"
import { ReactNode, useCallback } from "react"
import { useNavigate } from "react-router-dom"

interface MediaButtonProps {
    icon: ReactNode
    color: "default" | "error" | "info" | "inherit" | "primary" | "secondary" | "success" | "warning"
    externalUrl?: string
    internalUrl?: string
}

const BaseMediaButton = (props: MediaButtonProps) => {
    const {icon, color, externalUrl, internalUrl} = props
    const navigate = useNavigate()

    const handleClick = useCallback(() => {
        console.log(externalUrl)
        if (externalUrl) {
            window.open(externalUrl, '_blank');
        } else if (internalUrl) {
            // handle navigate
            navigate(internalUrl)
        }
    }, [externalUrl, internalUrl, navigate])
    return (
        <IconButton color={color} onClick={handleClick} sx={{ borderRadius: "8px", "& .MuiTouchRipple-root .MuiTouchRipple-child": {
            borderRadius: "8px"
          }}}>
            {icon}
        </IconButton>
    )
}
export default BaseMediaButton