import { Theme, createTheme } from "@mui/material";

interface GenerateThemeOptions {
    mode: 'dark' | 'light'
}

const baseThemeOptions: any = {
    typography: {
        fontFamily: 'Afacad, sans-serif'
    },
}

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#90caf9',
        },
        secondary: {
            main: '#f48fb1',
        },
    },
    ...baseThemeOptions,
})

const generateTheme = (options: GenerateThemeOptions): Theme => {
    if (options.mode === 'dark') {
        return darkTheme
    }
    return createTheme(...baseThemeOptions,)
}

export default generateTheme