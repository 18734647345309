import { Box, useTheme } from "@mui/material"



const DressrView = () => {
    const theme = useTheme()

    return (
        <Box sx={{ display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', backgroundColor: theme.palette.background.default }}>
            Dressr
        </Box>
    )
}
export default DressrView